<template>
  <div>
    <el-tabs v-model="form.eduVisible"
             @tab-click="search">
      <el-tab-pane label="我的资料"
                   name="3">
      </el-tab-pane>
      <el-tab-pane label="本校资料"
                   name="2"></el-tab-pane>
      <el-tab-pane label="公共资料"
                   name="1"></el-tab-pane>
    </el-tabs>
    <el-form :model="form"
             label-width="80px"
             ref="formRef">
      <el-row>
        <el-form-item label="科目：">
          <span v-for="item in subjectList"
                :key="item.subject_id"
                :class="{'is_active':item.subject_id == form.subjectId,'subject':true}"
                @click="setSubject(item.subject_id)">{{item.subject_name}}</span>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="类型：">
          <span v-for="item in fileTypeList"
                :key="item.value"
                :class="{'is_active':item.value == form.type,'subject':true}"
                @click="setTypeValue(item.value)">{{item.name}}</span>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="类别：">
          <span v-for="item in fileCategoryList"
                :key="item.edu_file_category_id"
                :class="{'is_active':item.edu_file_category_id == form.edu_file_category_id,'subject':true}"
                @click="setCategoryValue(item.edu_file_category_id)">{{item.name}}</span>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :lg="6"
                v-if="form.eduVisible == 3"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="审核状态:">
            <el-select v-model="form.status"
                       filterable
                       clearable
                       @change="search"
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in statusList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="文件名称:">
            <el-input clearable
                      placeholder="请输入文件名称"
                      @keyup.enter.native="search"
                      v-model="form.filename" />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="开始时间:">
            <el-date-picker v-model="form.beganTime"
                            style="width:100%"
                            @focus="$forbid"
                            @change="search"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="开始时间" />
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"
                :xs="24">
          <el-form-item label="结束时间:">
            <el-date-picker v-model="form.endTime"
                            style="width:100%"
                            @focus="$forbid"
                            @change="search"
                            type="datetime"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="结束时间" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10"
              style="padding:10px 0;">
        <el-col :span="24">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="toUpload">上传资源</el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-table border
              stripe
              :data="tableData"
              highlight-current-row
              :max-height="$store.state.tableHeight + 30"
              style="width: 100%">
      <el-table-column prop="filename"
                       v-if="tableColumns[0].isShow"
                       align="center"
                       label="文件名称">
        <template slot-scope="{row}">
          <div class="line"
               @click="preview(row)">
            {{row.filename}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="subject_cn"
                       align="center"
                       label="科目">

      </el-table-column>
      <el-table-column prop="type_name"
                       v-if="tableColumns[1].isShow"
                       align="center"
                       label="文件类型" />
      <el-table-column prop="edu_file_category_name"
                       v-if="tableColumns[7].isShow"
                       align="center"
                       label="文件类别" />
      <el-table-column prop="status"
                       v-if="tableColumns[2].isShow"
                       align="center"
                       label="审核状态">
        <template slot-scope="{row}">
          <span class="status weishanhe"
                v-if="row.status == '未审核'">{{row.status}}</span>
          <span class="status pass"
                v-if="row.status == '已通过'">{{row.status}}</span>
          <span class="status notpass"
                v-if="row.status == '未通过'">{{row.status}}</span>
          <span class="status pass"
                v-if="row.status == '已录入'">{{row.status}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="file_size"
                       v-if="tableColumns[3].isShow"
                       align="center"
                       label="大小" />
      <el-table-column prop="created_time"
                       v-if="tableColumns[4].isShow"
                       align="center"
                       label="上传时间	" />
      <el-table-column prop="description"
                       v-if="tableColumns[5].isShow"
                       align="center"
                       label="描述" />
      <el-table-column align="center"
                       width="250"
                       v-if="tableColumns[6].isShow"
                       label="操作">
        <template slot="header">
          <i class="el-icon-setting"
             slot="header"
             @click="showController"></i>
          操作
        </template>

        <template slot-scope="{row}">
          <div class="operyion_btn_icon">
            <el-tooltip effect="dark"
                        content="预览"
                        placement="top-start">
              <img src="@/assets/icons/icon-priview@2x.png"
                   style="margin-right:10px"
                   @click="preview(row)"
                   alt="">
            </el-tooltip>
            <!-- <el-tooltip effect="dark"
                        content="修改"
                        placement="top-start">
              <img src="@/assets/icons/icon-edit@2x.png"
                   @click="edit(row)"
                   alt="">
            </el-tooltip>
            <span style="width:20px;height:20px"></span> -->
            <el-tooltip effect="dark"
                        content="删除"
                        placement="top-start">
              <img src="@/assets/icons/icon-delete@2x.png"
                   v-if="(form.eduVisible != 1&&userInfo.is_research==1)||form.eduVisible== 3"
                   @click="delRow(row)"
                   alt="">
            </el-tooltip>
            <span style="width:10px;height:20px"></span>
            <el-tooltip effect="dark"
                        v-if="row.has_paper&&userInfo.is_research==1"
                        content="组卷"
                        placement="top-start">
              <img src="@/assets/icons/icon-zujuan@2x.png"
                   @click="showPaper(row)"
                   alt="">
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Shuttle ref="ShuttleRef" />
    <paperForm ref="paperFormRef" />
    <formDialog ref="formDialogRef" />
    <editForm ref="editFormDialogRef" />
    <previewDialog ref="previewDialogRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import formDialog from './components/form'
import editForm from './components/editForm.vue'
import previewDialog from './components/preview.vue'
import paperForm from '@/views/testPapercomposition/autonomously/components/index.vue'

export default {
  components: {
    formDialog, editForm, previewDialog, paperForm
  },

  data () {
    return {
      isEduAdmin: false,
      fileTypeList: [],
      fileCategoryList: [],
      statusList: [
        { label: '所有', value: '' },
        { label: '未审核', value: '1' },
        { label: '已通过', value: '2' },
        { label: '未通过', value: '3' },
      ],
      form: {
        subjectId: '',
        type: '',
        edu_file_category_id: '',
        status: '',
        filename: '',
        beganTime: '',
        endTime: '',
        eduVisible: '3',
        limit: 10,
        page: 1,
      },
      subjectList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'cz_schoolSource_list_admin',
      tableColumns: [
        { label: '文件名称', isShow: true },
        { label: '文件类型', isShow: true },
        { label: '审核状态', isShow: true },
        { label: '大小', isShow: true },
        { label: '上传时间', isShow: true },
        { label: '描述', isShow: true },
        { label: '操作', isShow: true },
        { label: '文件类别', isShow: true },
      ],
      userInfo: {}
    }
  },
  created () {
    var info = window.localStorage.getItem(this.pageName)
    if (info) {
      this.tableColumns = JSON.parse(info)
    }
  },
  mounted () {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    this.initSelect()
  },
  methods: {
    initData (page, limit) {
      if (this.form.page == 0) {
        return
      }
      this.form.page = page
      this.form.limit = limit
      let form = JSON.parse(JSON.stringify(this.form))
      if (form.eduVisible != 3) {
        form.status = '2'
      }
      this.$http({
        url: '/api/v1/file/lst',
        method: 'post',
        data: form
      }).then(res => {
        this.tableData = res.data.list
        this.isEduAdmin = res.data.isEduAdmin
        this.totalElements = res.data.count
      })
    },
    handleClick (e) { },
    preview (row) {
      this.$refs.previewDialogRef.text = row.filename
      this.$refs.previewDialogRef.info = row
      this.$refs.previewDialogRef.setIframe()
      this.$refs.previewDialogRef.url = row.url
      this.$refs.previewDialogRef.dialogVisible = true
    },
    edit (row) {
      let form = JSON.parse(JSON.stringify(row))
      var subjectList = JSON.parse(JSON.stringify(this.subjectList))
      this.$refs.editFormDialogRef.subjectList = subjectList
      subjectList.splice(0, 1)
      form = {
        id: row.id,
        filename: row.filename,
        subject_id: Number(form.subject_id),
        edu_visible: row.edu_visible,
        stu_visible: row.stu_visible,
        can_download: row.can_download + '',
        description: row.description,
        grade_ids: row.grade_ids,
        special_class_id: row.special_class_ids
      }
      if (this.userInfo.edu_school_id != 1) {
        this.$refs.editFormDialogRef.spliceArr(0)
      }
      this.$refs.editFormDialogRef.form = form
      this.$refs.editFormDialogRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/file/del',
          method: 'post',
          data: {
            id: row.id
          }
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.search()
        })
      })
    },
    // 查询
    search (e) {
      this.$refs.pagination.toFirstPage()
    },
    initSelect () {
      this.$http({
        url: '/api/v1/public/teacher_subject_lst',
        method: 'get'
      }).then(res => {
        this.subjectList = res.data.list
        this.subjectList.unshift({

          subject_id: '', subject_name: "所有",
        })
      })
      this.$http({
        url: '/api/v1/file/type',
        method: 'get'
      }).then(res => {
        this.fileTypeList = res.data.list
      })
      this.$http({
        url: '/api/v1/file/category',
        method: 'get',
        params: {
          show: 'all'
        }

      }).then(res => {
        this.fileCategoryList = res.data.list
      })
    },
    toUpload () {
      let form = {
        is_paper: 0,
        filename: 'upload_file',
        subject_id: '',
        edu_visible: 2,
        stu_visible: 1,
        description: '',
        grade_ids: [],
        paper_name: '',
        year: '',
        special_class_id: []
      }

      var subjectList = JSON.parse(JSON.stringify(this.subjectList))
      subjectList.splice(0, 1)
      // 如果不是善利的话  可以选本校和私有
      if (this.userInfo.edu_school_id != 1) {
        this.$refs.formDialogRef.spliceArr(0)
      }
      this.$refs.formDialogRef.subjectList = subjectList
      this.$refs.formDialogRef.form = form
      this.$refs.formDialogRef.dialogVisible = true
    },
    toAdjutList () {
      this.$router.push('/schoolSource/suditList')
    },
    setSubject (id) {
      this.form.subjectId = id
      this.search()
    },
    setTypeValue (value) {
      this.form.type = value
      this.search()
    },
    setCategoryValue (value) {
      this.form.edu_file_category_id = value
      this.search()
    },
    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    },
    showPaper (row) {
      let form = {
        paper_name: row.paper_name,
        paper_time: '',
        end_time: '',
        source_id: row.source_id,
        question_year: '',
        semester: 2,
        edu_paper_type: '',
        given_garde: '',
        paper_duration: '',
        given_class: '',
        subjectId: '',
        district_id: '',
      }
      this.$refs.paperFormRef.fromResource = true
      this.$refs.paperFormRef.form = form
      this.$refs.paperFormRef.getList()
      // this.$refs.paperFormRef.getInfo(row.source_id)
      this.$refs.paperFormRef.dialogVisible = true
      // 这个是预览试卷的页面，不要删除，等着对接组卷弹窗那里对接完
      // this.$router.push('/schoolSource/reviewPaper?form=' + JSON.stringify(form))

    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
  font-size: 19px;
  width: 150px;
  text-align: center;
  padding-right: 10px;
  padding: 0;
}
.is_active {
  color: #409eff;
}
.subject {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
.status {
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}
.weishanhe {
  color: #3f9eff;
  background: #eff8ff;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.pass {
  color: #41d7d7;
  background: #effffe;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.notpass {
  color: #fe765f;
  background: #ffefef;
  padding: 0px 10px;
  display: inline-block;
  border-radius: 5px;
}

.line {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}
.table_line {
  width: 100%;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
</style>