<template>
  <div>
    <el-dialog title="上传资源"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               label-width="100px"
               :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="科目:"
                          prop="subject_id">
              <el-select v-model="form.subject_id"
                         style="width:100%"
                         placeholder="请选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="是否试卷:"
                          prop="is_paper">
              <el-switch v-model="form.is_paper"
                         active-text="是"
                         inactive-text="否"
                         active-color="#13ce66"
                         inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="教师权限:">
              <el-select v-model="form.edu_visible"
                         style="width:100%"
                         placeholder="请选择">
                <el-option :label="item.label"
                           v-for="item in option"
                           :key="item.value"
                           :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="!show">
          <el-row>
            <el-col :span="24">
              <el-form-item label="学生权限:">
                <el-select v-model="form.stu_visible"
                           @change="change"
                           style="width:100%"
                           placeholder="请选择">
                  <el-option :label="item.label"
                             v-for="item in stuOption"
                             :key="item.value"
                             :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="form.stu_visible == 1">
            <el-row>
              <el-col :span="24">
                <el-form-item label="年级:">
                  <el-select v-model="form.grade_ids"
                             multiple
                             @change="clearClass"
                             style="width:100%"
                             placeholder="请选择">
                    <el-option v-for="item in gradeList"
                               :key="item.grade_id"
                               :label="item.grade_name "
                               :value="item.grade_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="24">
                <el-form-item label="班级:">
                  <el-select v-model="form.special_class_id"
                             multiple
                             @change="clearGrade"
                             style="width:100%"
                             placeholder="请选择">
                    <el-option v-for="item in classList"
                               :key="item.id"
                               :label="item.class_name + '-' + item.year_desc"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-else>
          <el-row>
            <el-col :span="24">
              <el-form-item label="学生权限:">
                <el-select v-model="form.stu_visible"
                           @change="change"
                           style="width:100%"
                           placeholder="请选择">
                  <el-option :label="item.label"
                             v-for="item in stuOption"
                             :key="item.value"
                             :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="试卷名称"
                            prop="paper_name">
                <el-input clearable
                          style="width:100%"
                          v-model="form.paper_name"
                          placeholder="请输入" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="年份"
                            prop="year">
                <el-date-picker v-model="form.year"
                                type="year"
                                style="width:100%"
                                value-format="yyyy"
                                placeholder="选择年">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-row>
          <el-col :span="24">
            <el-form-item label="文件类别:">
              <el-select v-model="form.edu_file_category_id"
                         @change="change"
                         style="width:100%"
                         placeholder="请选择"
                         clearable>
                <el-option :label="item.name"
                           v-for="item in fileCategoryOptions"
                           :key="item.edu_file_category_id"
                           :value="item.edu_file_category_id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="选择文件">
              <div class="addFile"
                   v-if="fileList.length == 0"
                   @click="upload">
                <i class="el-icon-plus"></i>
              </div>
              <div v-else>
                <el-tag closable
                        v-for="(item,index) in fileList"
                        :key="index"
                        @close="clearFile"
                        type="priamry">
                  {{item.name}}
                </el-tag>
              </div>
            </el-form-item>
            <p>请上传jpeg，gif，png，txt，mp4，mpeg，ppt，pptx，doc、docx，pdf后缀文件</p>

          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="描述">
              <el-input clearable
                        v-model="form.description"
                        placeholder="请输入描述" />
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="createSource">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      form: {},
      text: '上传',
      rules: {
        subject_id: [
          { required: true, message: '请选择科目', trigger: 'change' }
        ],
        paper_name: [
          { required: true, message: '请输入', trigger: 'change' }
        ],
        year: [
          { required: true, message: '请输入', trigger: 'change' }
        ],
      },
      gradeList: [],
      fileCategoryOptions: [],
      fileList: [],
      subjectList: [],
      classList: [],
      dialogVisible: false,
      option: [
        { value: 1, label: '公共' },
        { value: 2, label: '本校' },
        { value: 3, label: '私有' },
      ],
      stuOption: [
        { value: 0, label: '所有学生' },
        { value: 1, label: '本校学生' },
      ]
    }
  },
  watch: {
    'form.is_paper': {
      handler () {
        if (this.form.is_paper) {
          this.show = true
          this.$set(this.form, 'year', '2022')
        } else {
          this.show = false
          this.form.year = ''
        }
      },
      deep: true
    }
  },
  mounted () {
    this.getRole()
    this.getList()
    this.getFileCategoryOptions()
  },
  methods: {
    clearClass () {
      this.form.special_class_id = []
    },
    spliceArr (index) {
      this.option.splice(index, 1)
      this.stuOption.splice(index, 1)
    },
    clearGrade () {
      this.form.grade_ids = []
    },
    getList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })

      this.$http({
        url: '/api/v1/special/tc_lead_class',
        method: 'get',
        params: {
          grade_id: this.form.grade_ids,
        }
      }).then(res => {
        this.classList = res.data
      })
    },
    getRole () {
      this.$http({
        url: '/api/v1/file/school_permission',
        method: 'get',
      }).then(res => {
        this.permission = res.data.permission
      })
    },
    getFileCategoryOptions () {
      this.$http({
        url: '/api/v1/file/category',
        method: 'get',
      }).then(res => {
        this.fileCategoryOptions = res.data.list
      })
    },
    change () {
      this.form.grade_ids = []
      this.form.special_class_id = []
    },
    handleClose () {
      this.stuOption = [
        { value: 0, label: '所有学生' },
        { value: 1, label: '本校学生' },
      ]
      this.option = [
        { value: 1, label: '公共' },
        { value: 2, label: '本校' },
        { value: 3, label: '私有' },
      ]
      this.$refs.formRef.resetFields()
      this.fileList = []
      this.dialogVisible = false
    },
    upload () {
      this.$get_file({
        limit: 256
      }).then(raw => {
        this.fileList = []
        this.fileList.push(raw)
      })
    },
    clearFile () {
      this.fileList.splice(0, 1)
    },
    createSource () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let formData = new FormData();
        if (this.fileList.length) {
          formData.append('upload_file', this.fileList[0])
        }
        let form = JSON.parse(JSON.stringify(this.form))
        form.year = form.year ? Number(form.year) : ''
        form.is_paper = form.is_paper ? 1 : 0

        for (const key in form) {
          formData.append(key, form[key])
        }
        form.special_class_id = form.special_class_id.join(',')
        form.grade_ids = form.grade_ids.join(',')

        formData.append('sign', this.$getMd5Form(form))

        this.$http({
          url: '/api/v1/file/create',
          Headers: {
            'Content-Type': 'multipart/form-data'
          },
          method: 'post',
          data: formData
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '上传资源成功，等待审核!',
            type: 'success'
          });
          this.handleClose()
          this.$parent.search()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
::v-deep .dialog-footer {
  text-align: center;
}
.addFile {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  cursor: pointer;
  line-height: 146px;
  vertical-align: top;
  text-align: center;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>